import { Component } from '@angular/core';
import { StorageService } from './common/services/storage/storage.service';
import { AuthService } from './common/services/auth/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private _storageService: StorageService,
    private authService: AuthService
  ) {
    const packageData = this._storageService.getDataFromStorage('arrayPlanAccessKey');
    if (packageData) {
      this.authService.updateuserAccesPlanData(JSON.parse(packageData));
    }
  }
}
