export const DASHBOARD_HEADING = {
  allAccounts: {
    heading: 'All Accounts',
    subHeading: 'See details of user accounts in Swire Shipping in your region',
  },
  business: {
    heading: 'Business Accounts',
    subHeading:
      'Manage requests and see details of new and existing business accounts in Swire Shipping in your region',
  },
};

export const AUTH_HEADING = {
  signup: {
    heading: '<strong>E</strong>LEVATE <span class="link">by FR8Connect</span>',
    subHeading: 'Streamlining and enhancing your productivity with ELEVATE, designed to help your business thrive.',
    form: {
      heading: 'Create your account',
      subHeading: 'Enter your personal details to create an account',
    },
  },
  login: {
    heading: '<strong>E</strong>LEVATE <span class="link">by FR8Connect</span>',
    subHeading: 'Streamlining and enhancing your productivity with ELEVATE, designed to help your business thrive.',
    form: {
      heading: 'Sign In to account',
      subHeading: 'Enter your email & password to login',
    },
  },
  welcome: {
    shipper: {
      heading: 'Profile Setup',
      subHeading: 'Questionnaire and Required Documents',
      body: `
            <p>We are excited that you have decided to join FR8Connect to help <b>ELEVATE</b> your shipping operations.</p>

            <p>To better serve you, please complete the following questionnaire. This will provide us with a clear
            overview of your company, your shipping needs, and your goals. This information is essential for
            setting up your account profile and tailoring our services to better fit your needs.</p>
            
            <p>Please also upload any required documents and your logo if applicable. These items are necessary
            for creating your enhanced shipper packet. You can submit these documents using the file uploader
            within the questionnaire.</p>
            <p>We encourage you to complete the questionnaire at your earliest convenience to expedite the setup
            of your profile and access all selected products. If you have any questions, feel free to contact us at
            (866) 342-0040 or via email at <a href="mailto:support@fr8connect.com">support@fr8connect.com</a>.</p>

            <p>Thank you,</p>
            <p>ELEVATE by FR8Connect</p>`,
    },
    carrier: {
      heading: 'Profile Setup',
      subHeading: 'Questionnaire and Required Documents',
      body: `
            <p>We are excited you have decided to joined FR8Connect to help <b>ELEVATE </b>your business to new heights.</p>
            <p>The next step is to fill out the following questionnaire which will give us a better overview of you, your
            company and goals. This is required to complete the setup of your account profile and to provide
            selected services.</p>
            <p>Within the questionnaire, we also request a series of documents and your logo, if applicable, that
            would be used to create your enhanced <b>carrier</b> packet. The documents can be submitted using the file
            uploader within the questionnaire. </p>
            <p>Please <b>fully</b> complete the questionnaire at your earliest convenience as this will complete setup of
            your profile and provide access to all selected products.<b>The sooner we receive the information,
            the sooner we can complete the setup of your account.</b> </p>
            <p>If you have any questions at all, please don't hesitate to let us know. Give us a call at (866) 342-0040
            x109, or send an email to <a href="mailto:support@fr8connect.com">support@fr8connect.com</a>, and we will respond as soon as possible.</p>
            <p>Thank you,</p>
            <p>ELEVATE by FR8Connect</p>`,
    },
    broker: {
      heading: 'Profile Setup',
      subHeading: 'Questionnaire and Required Documents',
      body: `
            <p>We are thrilled to have you join FR8Connect to <b>ELEVATE</b> your freight brokerage business to new
            heights. To get started, please complete the enclosed questionnaire. Your responses will help us
            better understand your business and goals, enabling us to tailor your account profile and selected
            services effectively.</p>
            <p>This information will also assist in creating your enhanced broker packet, which includes a customized
            coversheet to promote your services. Please include any relevant documents and your logo using the
            file uploader within the questionnaire.</p>
            <p>Complete this questionnaire at your earliest convenience to finalize the setup of your profile and unlock
            all selected products. Should you have any questions, do not hesitate to contact us at (866) 342-0040
            or <a href="mailto:support@fr8connect.com">support@fr8connect.com</a>.</p>
           
            <p>Thank you,</p>
            <p>ELEVATE by FR8Connect</p>`,
    },
    dispatcher: {
      heading: 'Profile Setup',
      subHeading: 'Questionnaire and Required Documents',
      body: `
            <p>Thank you for choosing FR8Connect to help <b>ELEVATE</b> your dispatching business. We are eager to
            assist you in enhancing your operations and reaching new heights.</p>
            <p>Please fill out the following questionnaire to provide us with a better understanding of your company
            and your goals. This is essential for completing the setup of your dispatcher profile and for tailoring
            our services to meet your needs.</p>
            <p>Included in the questionnaire are requests for specific documents and your logo (if applicable), which
            are needed to create your enhanced dispatcher packet. These documents can be submitted using the
            file uploader within the questionnaire.</p>
            <p>Complete this questionnaire as soon as possible to finalize your profile setup and gain access to all
            selected products. If you have any questions, please contact us at (866) 342-0040 x109 or
            <a href="mailto:support@fr8connect.com">support@fr8connect.com</a>.</p>
           
            <p>Thank you,</p>
            <p>ELEVATE by FR8Connect</p>`,
    },
    other: {
      heading: 'Profile Setup',
      subHeading: 'Questionnaire and Required Documents',
      body: `
            <p>Welcome to FR8Connect! Whether you’re seeking high-quality educational content to better understand
            the freight trucking industry or considering a career in this dynamic field, you’ve come to the right place.
            Our comprehensive resources and training programs are designed to help you make informed decisions
            about joining the transportation industry.</p>
            <p>To get started, please complete the following questionnaire for our records. This will help us tailor our
            offerings to your needs. Afterward, you can select the educational programs and materials that best suit
            you. Please note that some content may require a fee to access.</p>
           
           <p>If you have any questions or need assistance, feel free to reach out to us at (866) 342-0040 or via email
            at  <a href="mailto:support@fr8connect.com">support@fr8connect.com</a>. We look forward to supporting your journey and helping you make well-
            informed decisions about your future in the freight trucking industry.</p>
            <p>Thank you,</p>
            <p>ELEVATE by FR8Connect</p>`,
    },
  },
};

export const ROLES = {
  admin: 'admin',
  superAdmin: 'ss-admin',
};
export const ROLE_ACCESS = [ROLES.superAdmin];

export const INVALID_ROLES = {
  heading: 'Access Denied',
  subHeading: `
    <p>
    It seems like you have stumbled upon a restricted space. 
    You don’t have permission to view this page. <br>Kindly reach out to your administrator for permission details</p>
    `,
  leftbtn: 'Previous Page',
  rightbtn: 'Try Again',
};
export const PAGE: any = {
  is_deleted: 0,
  sort_by: 'createdAt',
  sort_order: 'DESC',
  offset: 0,
  limit: 10,
  total: 0,
  current: 1,
};
export function createPageObject() {
  return {
    is_deleted: 0,
    sort_by: 'createdAt',
    sort_order: 'DESC',
    offset: 0,
    limit: 10,
    total: 0,
    current: 1,
  };
}
export const INVALID_PACKAGE = {
  heading: 'Unauthorized!',
  subHeading: `
    <p>
    It seems like you have stumbled upon a restricted space. 
    You don’t have permission to view this page. <br>Kindly reach out to your administrator for permission details</p>
    `,
  leftbtn: 'Previous Page',
  rightbtn: 'Try Again',
};

export const API_RESPONSE = {
  signUp: {
    toster: {
      error: 'An error occurred. Please try again later.',
      unauthorized: 'You have not a valid role to signup!',
    },
  },
  emaiVerification: {
    toster: {
      success: 'You have been successfuly registered with email verification',
      error: 'An error occurred. Please try again later.',
      invalidVerification: 'Invalid verification code.',
      chnageEmail: 'Your Email addredd changed successfully.',
    },
  },
  resendEmailVerification: {
    toster: {
      success: 'We have sent you the new verification code on your email',
      error: 'An error occurred. Please try again later.',
      invalidVerification: 'Invalid verification code.',
    },
  },
  login: {
    toster: {
      success: function (userName: string) {
        return `Welcome back, ${userName}! Here’s what’s happening with your account today.`;
      },
      error: 'An error occurred. Please try again later.',
      invalidVerification: 'Invalid verification code.',
    },
  },
  resetPassword: {
    toster: {
      success: 'You have successfully changed your password',
      error: 'An error occurred. Please try again later.',
      invalidVerification: 'Invalid verification code.',
    },
  },
  forgotEmailPassword: {
    toster: {
      success: 'Reset password link has been sent to your registered email address',
      error: 'An error occurred. Please try again later.',
    },
  },
  patchUser: {
    toster: {
      updateSuccess: 'User Updated Successfully',
      error: 'An error occurred. Please try again later.',
    },
  },
  profile: {
    toster: {
      delete: 'Profile photo deleted successfully',
      error: 'An error occurred. Please try again later.',
      validationError: 'Validation Error.',
    },
  },
  questionAddEdit: {
    toster: {
      success: 'Questionnaire data updated successfully.',
      error: 'An error occurred. Please try again later.',
      validationError: 'Validation Error.',
    },
  },
  assignAgent: {
    toster: {
      companyRequired: 'Please select a company for assign agent!',
      agentRequired: 'Please select agent for company!',
      alreadyAssignd: 'Agent already assign .Please select different agent if you want to change it.',
    },
  },

  leadMessage: {
    toster: {
      success: 'Lead Delete successfully',
      restore: 'Lead Restored successfully',
      error: 'Please select the lead/leads that you want to delete.',
      fileImport: 'Please choose a file',
      fileSuccess: 'Leads imported successfully',
      failImport: 'Failed to import leads, Please view the above provided sample file',
    },
  },
};
