import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  BACKEND_URL = environment.BACKEND_URL;
  constructor(private _http: HttpClient) {}

  sendPostRequest(
    requestUrl: string,
    postData: any,
    responseType: any = {
      responseType: 'json', //magic
    }
  ) {
    return this._http.post(this.BACKEND_URL + requestUrl, postData, responseType);
  }

  sendPutRequest(
    requestUrl: string,
    postData: any,
    responseType: any = {
      responseType: 'json', //magic
    }
  ) {
    return this._http.put(this.BACKEND_URL + requestUrl, postData, responseType);
  }

  sendPatchRequest(
    requestUrl: string,
    postData: any,
    responseType: any = {
      responseType: 'json', //magic
    }
  ) {
    return this._http.patch(this.BACKEND_URL + requestUrl, postData, responseType);
  }

  sendGetRequest(requestUrl: string, params?: any) {
    return this._http.get(this.BACKEND_URL + requestUrl, { params });
  }

  sendPostRequestFile(
    requestUrl: string,
    postData: any,
    responseType: any = {
      responseType: 'arraybuffer', //magic
    }
  ) {
    return this._http.post(this.BACKEND_URL + requestUrl, postData, responseType);
  }

  sendTokenRequest(requestUrl: string, szSecretKey: string) {
    const postData = {
      payload: szSecretKey,
    };
    return this._http.post(this.BACKEND_URL + requestUrl, postData);
  }

  sendGetRequestWithParam(requestUrl: string, page: any) {
    const options = page
      ? {
          params: new HttpParams()
            .set('whereCondition', JSON.stringify(page.whereCondition))
            .set('offset', page.offset)
            .set('limit', page.limit)
            .set('sortBy', page.sortBy)
            .set('sortOrder', page.sortOrder),
        }
      : {};
    return this._http.get(this.BACKEND_URL + requestUrl, options);
  }
  sendGetRequestFile(
    requestUrl: string,
    responseType: any = {
      responseType: 'arraybuffer', //magic
    }
  ) {
    return this._http.get(this.BACKEND_URL + requestUrl, responseType);
  }
  sendDeleteRequestFile(requestUrl: string) {
    return this._http.delete(this.BACKEND_URL + 'api/v1/' + requestUrl);
  }
  sendDeleteRequest(requestUrl: string) {
    return this._http.delete(this.BACKEND_URL + requestUrl);
  }
  sendGetRequestWithBlobResponse(url: string): Observable<Blob> {
    return this._http.get(url, { responseType: 'blob' });
  }
}
