import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { INVALID_PACKAGE } from 'src/app/common/constant/constant-variable/ConstantVariable';
import { ROUTING } from 'src/app/common/constant/routing/Routes';

@Component({
  selector: 'app-invalid-package',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './invalid-package.component.html',
  styleUrl: './invalid-package.component.scss',
})
export class InvalidPackageComponent {
  INVALID_PACKAGE = INVALID_PACKAGE;
  ROUTING = ROUTING;
  constructor(private router: Router) {}
  redirectOnCLick(path: string) {
    this.router.navigate([path]);
  }
}
