import { Component } from '@angular/core';
import { INVALID_ROLES } from 'src/app/common/constant/constant-variable/ConstantVariable';
// import { DomSanitizePipe } from '../../../data/pipes/domSanitize/dom-sanitize.pipe';

@Component({
  selector: 'app-invalid-route',
  standalone: true,
  imports: [],
  templateUrl: './invalid-route.component.html',
  styleUrls: ['./invalid-route.component.scss'],
})
export class InvalidRouteComponent {
  INVALID_ROLES = INVALID_ROLES;
  constructor() {}
  tryAgain() {
    history.go(-2);
  }
}
