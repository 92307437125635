import { Injectable, signal } from '@angular/core';
import { MainService } from '../main/main.service';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { AuthData } from 'src/app/common/interface/auth/user.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authData: AuthData = {
    isFirstLogin: false,
    isPlan: false,
    userKey: '',
    fullName: '',
    accessToken: '',
    role: '',
    questionnaireStep: 0,
  };
  isUserLoggedIn: boolean = false;

  private userAuthData = new BehaviorSubject({});
  authDataCast = this.userAuthData.asObservable();

  private questionsData = new BehaviorSubject({});
  questionsDataCast = this.questionsData.asObservable();

  isUserUpdatePassword: boolean = false;
  BACKEND_URL = environment.BACKEND_URL;

  current_step = signal(1);
  constructor(
    private _mainService: MainService,
    private _storageService: StorageService,
    private _http: HttpClient
  ) {
    this.getAuthUserData();
  }

  // Used Mehod
  signup(req: any) {
    return this._mainService.sendPostRequest('signup', req);
  }
  login(req: any) {
    return this._mainService.sendPostRequest('login', req);
  }
  verifyEmail(req: any) {
    return this._mainService.sendPostRequest('code-verification', req);
  }

  resendEmailVerificationCode(req: any) {
    return this._mainService.sendPostRequest('resend-code', req);
  }
  resetPassword(req: any) {
    return this._mainService.sendPostRequest('reset-password', req);
  }

  forgotPasswordEmail(req: any) {
    return this._mainService.sendPostRequest('forgot-password-email	', req);
  }

  uploadMultiple(req: any) {
    return this._mainService.sendPostRequest('upload/multiple', req);
  }

  updateUserPassowrd(isPassword: boolean) {
    this.isUserUpdatePassword = isPassword;
  }

  captchaVerification(req: any) {
    return this._mainService.sendPostRequest('captcha-verification', req);
  }

  //###############################################################################################
  // ****************************Authenticated routes********************************************##
  //###############################################################################################

  patchPassword(req: any) {
    return this._mainService.sendPatchRequest('api/v1/master/reset-password', req);
  }
  patchProfile(req: any) {
    return this._mainService.sendPatchRequest('api/v1/master/patchUser', req);
  }
  getQuestionnaire(req: any) {
    return this._mainService.sendGetRequest('api/v1/master/question', req);
  }
  getProfile(req: any) {
    return this._mainService.sendPostRequest('api/v1/master/profile', req);
  }
  chnageUserEmail(req: any) {
    return this._mainService.sendPostRequest('api/v1/master/change-email', req);
  }

  addUser(req: any) {
    return this._mainService.sendPostRequest('api/v1/master/add/agent', req);
  }
  deleteUser(userId: number) {
    return this._mainService.sendDeleteRequest('api/v1/master/user?id=' + userId);
  }
  getAdminUsers(req: any) {
    return this._mainService.sendGetRequest('api/v1/master/users', req);
  }
  logout(req: any) {
    return this._mainService.sendPostRequest('api/v1/master/logout', req);
  }
  deleteAccount(req: any) {
    return this._mainService.sendPostRequest('api/v1/master/account', req);
  }
  sendPatchRequest(
    requestUrl: string,
    postData: any,
    responseType: any = {
      responseType: 'json', //magic
    }
  ) {
    return this._http.patch(this.BACKEND_URL + requestUrl, postData, responseType);
  }

  getAuthUserData() {
    const authData = this._storageService.getDataFromStorage('authData');
    if (authData) {
      this.isUserLoggedIn = true;
      this.authData = JSON.parse(authData);
      this.userAuthData.next(this.authData);
      return this.authData;
    } else {
      this.isUserLoggedIn = false;
      this.userAuthData.next({});
      return null;
    }
  }

  updateAuthUserData(authData: any) {
    // var szCustomerName = authData.szCustomerName;
    // var splitted = szCustomerName.split(" ");
    // var length = splitted.length;
    // if (length > 1) {
    //   authData.szCustomerImageName = splitted[0] + " " + splitted[1];
    // } else {
    //   authData.szCustomerImageName = authData.szCustomerName;
    // }
    this.authData = authData;
    this.isUserLoggedIn = true;
    this._storageService.putDataInStorage('authData', JSON.stringify(authData));
    this.userAuthData.next(this.authData);
    this.getAuthUserData();
  }

  removeAuthUserData() {
    this.authData = {
      isFirstLogin: false,
      isPlan: false,
      userKey: '',
      fullName: '',
      accessToken: '',
      role: '',
      questionnaireStep: 0,
    };
    this.isUserLoggedIn = false;
    this._storageService.removeDataFromStorage('authData');
    this._storageService.removeDataFromStorage('questionnaireRequirementsFormPart1');
    this._storageService.removeDataFromStorage('questionnaireRequirementsFormPart2');
    this._storageService.removeDataFromStorage('questionnaireRequirementsFormPart3');
    this.userAuthData.next({});
  }

  addQuestionsData(questionsData: any) {
    this.questionsData.next({});
    this.questionsData.next(questionsData);
    // debugger;
  }
  getQuestionsData() {
    return this.questionsDataCast;
  }

  sendPostRequest(
    requestUrl: string,
    postData: any,
    responseType: any = {
      responseType: 'json', //magic
    }
  ) {
    return this._http.post(this.BACKEND_URL + requestUrl, postData, responseType);
  }

  generateCoversheet(data: any) {
    return this._mainService.sendPostRequest('api/v1/master/coversheet', data, {
      responseType: 'arraybuffer',
    });
  }
  getAddress() {
    return this._mainService.sendGetRequest('master/csc');
  }
  transferLead(req: any) {
    return this._mainService.sendPostRequest('api/v1/master/lead/transfer', req);
  }
}
