export enum ModuleType {
  ManageAuth = 'auth',
  ManageDashboard = 'dashboard',
  ManageLead = 'lead',
  ManageCRM = 'rms',
  ManageGuide = 'guide',
  ManageForm = 'form',
  ManageMotivation = 'motivation',
  LetterBox = 'letter-box',
  ManageUser = 'user',
  ManageSales = 'sale',
  ManagePlan = 'plan',
  ManageTemplete = 'template',
  ManageCoupon = 'coupon',
  Package = 'package',
  ManageOrders = 'orders',
  Analytics = 'analytics',
}
export enum ModulesName {
  'dashboard' = 'Dashboards',
  'lead' = 'Lead Management',
  'rms' = 'RMS',
  'guide' = 'Guides',
  'coupon' = 'Coupons',
  'form' = 'Forms',
  'motivation' = 'Articles, Audio, Videos and Audio Books',
  'letter-box' = 'Letter-box',
  'user' = 'Users',
  'template' = 'Templates',
  'sale' = 'FR8Calls',
  'plan' = 'Plans',
  'analytics' = 'Analytics',
  'orders' = 'Purchased plans',
}
