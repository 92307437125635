<section class="denied-section bg-grey-20">
  <div class="denied-content">
    <span>
      <img src="../../../assets/images/accessDenied.png" alt="" />
    </span>
    <h2 class="mb-0" [innerHTML]="INVALID_PACKAGE.heading"></h2>
    <p [innerHTML]="INVALID_PACKAGE.subHeading"></p>
    <div class="denied-button denied-button gap-3 d-flex">
      <button (click)="redirectOnCLick(ROUTING.HOME)" routerLinkActive="router-link-active" class="btn btn-primary">
        Home
      </button>
      <button class="btn btn-primary" (click)="redirectOnCLick(ROUTING.PACKAGES)">View Plans</button>
    </div>
  </div>
</section>
