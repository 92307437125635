import { Injectable } from '@angular/core';
import { ROLE_ACCESS } from '../../constant/constant-variable/ConstantVariable';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  ROLE_ACCESS = ROLE_ACCESS;
  public customerKey: string = '';
  public authData: any;
  putDataInStorage(storageKey: any, storageData: any) {
    localStorage.setItem(storageKey, storageData);
  }

  getDataFromStorage(storageKey: any) {
    return localStorage.getItem(storageKey);
  }

  removeDataFromStorage(storageKey: any) {
    return localStorage.removeItem(storageKey);
  }
  updateAuthData(data: any) {
    this.authData = data;
    localStorage.setItem('authData', JSON.stringify(data));
  }
  checkPermission() {
    // let arrRoles = this.authData?.realm_access?.roles;
    let arrRoles: any = localStorage.getItem('authData');
    if (arrRoles) {
      arrRoles = JSON.parse(arrRoles);
    }
    return arrRoles?.realm_access?.roles.some((r: any) => this.ROLE_ACCESS?.includes(r));
  }
}
