export const environment = {
  name: 'dev',
  production: false,
  BACKEND_URL: 'https://apifr8connect.whiz-solutions.com/',
  newsletter_mailchimp_key: '26dfd9b7adec5c360d4004af287bb660-us22',
  mailchimp_audienceId: 'c198c8439c',
  CAPTCHA_KEY: '6LciPgYqAAAAAAT4S2UWlR5C1IH6GiBahKYq2EPF',
  imageUrl: '',
  CMS_URL: 'https://fr8connect.whiz-solutions.com/',
  STRIPE_API_KEY:
    'pk_test_51Ou51tSG8Dax1J3sywkPpJs6oWFCE4toW4OpWhvZOIFcNwEq4eeg4T28f6tjAnAfeCBGOZVolzSxUqCWfwN3DNxb00eyQlUSp5',
  STRIPE_SECRET_KEY:
    'sk_test_51Ou51tSG8Dax1J3s0Po6Hu1yeRwJm2UUTYT557NkMq3FH9A7kihYgHHjlofScuJJ0u3Ob6dFSMTi7bSCdaNizfzA00v1zicmCh',
};
