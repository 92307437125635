import { ModuleType } from '../../enum/module-type/ModuleType.enum';
export const ROUTING = {
  CRM_LEAD_MANAGEMENT: `/${ModuleType.ManageCRM}/lead-management`,
  CRM_LEAD_CREATE: `/${ModuleType.ManageCRM}/create-lead`,
  CRM_LEAD_DETAILS: `/${ModuleType.ManageCRM}/lead-details`,
  CRM_LEAD_EDIT: `/${ModuleType.ManageCRM}/edit-lead`,

  GUIDE_MANAGEMENT: ``,
  GUIDE_CREATE: `/${ModuleType.ManageGuide}/create`,
  GUIDE_LIST: `/${ModuleType.ManageGuide}/list`,
  GUIDE_EDIT: `/${ModuleType.ManageGuide}/edit`,
  GUIDE_DETAILS: `/${ModuleType.ManageGuide}/details`,

  COUPON_MANAGEMENT: ``,
  COUPON_CREATE: `/${ModuleType.ManageCoupon}/create`,
  COUPON_LIST: `/${ModuleType.ManageCoupon}/list`,
  COUPON_EDIT: `/${ModuleType.ManageCoupon}/edit`,
  COUPON_DETAILS: `/${ModuleType.ManageCoupon}/details/`,

  FORM_MANAGEMENT: ``,
  FORM_CREATE: `/${ModuleType.ManageForm}/create`,
  FORM_LIST: `/${ModuleType.ManageForm}/list`,
  FORM_EDIT: `/${ModuleType.ManageForm}/edit`,
  FORM_DETAILS: `/${ModuleType.ManageForm}/details`,

  FORM_MOTIVATION: ``,
  MOTIVATION_CREATE: `/${ModuleType.ManageMotivation}/create`,
  MOTIVATION_LIST: `/${ModuleType.ManageMotivation}/list`,
  MOTIVATION_EDIT: `/${ModuleType.ManageMotivation}/edit`,
  MOTIVATION_DETAILS: `/${ModuleType.ManageMotivation}/details`,

  PLAN_MOTIVATION: ``,
  PLAN_CREATE: `/${ModuleType.ManagePlan}/create`,
  PLAN_LIST: `/${ModuleType.ManagePlan}/list`,
  PLAN_EDIT: `/${ModuleType.ManagePlan}/edit`,

  USER_MOTIVATION: ``,
  USER_CREATE: `/${ModuleType.ManageUser}/add`,
  USER_UPDATE: `/${ModuleType.ManageUser}/edit`,

  USER_PROFILE: `/${ModuleType.ManageUser}/profile`,
  USER_LIST: `/${ModuleType.ManageUser}/list`,

  COMPANY_LIST: `/${ModuleType.ManageSales}/company/list`,
  FR8COMPANY_LIST: `/${ModuleType.ManageSales}/company/list`,
  COMPANY_LEAD_LIST: `/rms/sales/lead-management/1`,
  SALES_LEAD_LIST: `/rms/sales/lead-management`,
  SALES_LEAD_CREATE: `/rms/sales/create-lead`,
  SALES_LEAD_DETAILS: `/rms/sales/lead-details`,

  TEMPLETE_MANAGEMENT: ``,
  TEMPLETE_CREATE: `/${ModuleType.ManageTemplete}/create`,
  TEMPLETE_LIST: `/${ModuleType.ManageTemplete}/list`,

  HOME: `/`,
  INVALID_ROLE: `/invalid-role`,
  INVALID_PACKAGE: `/unauthorized`,
  LOGIN: `/auth/login`,
  REGISTER: '/auth/register',
  WELCOME: '/auth/welcome',
  PROFILE: '/auth/profile',
  LEAD_MANAGEMENT: '/rms/lead-management',
  LEAD_DETAILS: '/rms/lead-details',
  CODE_VERIFICATION: 'auth/code-verification',
  FORGET_PASSWOD: '/auth/forget-password-email',

  QUESTIONNAIRE: 'auth/questionnaire-requirements',
  EDIT_PROFILE: '/user/profile',
  EDIT_LEAD: '/rms/edit-lead',
  EDIT_SALE_LEAD: '/rms/sales/edit-lead',

  PACKAGES: '/package',
  CHECKOUT: '/package/checkout',

  All_ORDERS: '/orders/list',
  ORDERS_DETAILS: '/orders/detail',

  ANALYTICS_MANAGEMENT: ``,
  //ANALYTICS: `/${ModuleType.Analytics}`,
  ANALYTICS: '/analytics',
};

export const ACCESS_KEYS = {
  ACCESS_RMS_MANAGEMENT: [
    'PLAN_KEY_1',
    'PLAN_KEY_1_50',
    'PLAN_KEY_1_100',
    'PLAN_KEY_1_200',
    'PLAN_KEY_1_300',
    'PLAN_KEY_1_400',
    'PLAN_KEY_1_500',
    'PLAN_KEY_1_10000',
    'PLAN_KEY_1_ULLIMITED',
  ],
  ACCESS_GUIDE_MANAGEMENT: ['PLAN_KEY_2'],
  ACCESS_FORM_MANAGEMENT: ['PLAN_KEY_2'],

  ACCESS_SALES_MANAGEMENT: ['PLAN_KEY_13', 'PLAN_KEY_13_250', 'PLAN_KEY_13_500'],
  ACCESS_SALES_CRM_MANAGEMENT: [
    'PLAN_KEY_1_50',
    'PLAN_KEY_1_100',
    'PLAN_KEY_1_200',
    'PLAN_KEY_1_300',
    'PLAN_KEY_1_400',
    'PLAN_KEY_1_500',
    'PLAN_KEY_1_10000',

    'PLAN_KEY_1_ULLIMITED',
    'PLAN_KEY_13',
    'PLAN_KEY_13_250',
    'PLAN_KEY_13_500',
  ],
  ACCESS_PROGRESS_REPORT_MANAGEMENT: ['PLAN_KEY_3', 'PLAN_KEY_13_39'], //ANALYTICS

  ACCESS_MOTIVATION_MANAGEMENT: ['PLAN_KEY_4'], //AUDIO VIDEO ARTICAL
  ACCESS_RMS_SALES_MANAGEMENT: ['PLAN_KEY_1', 'PLAN_KEY_5'], //FR8CALLS
  ACCESS_PODCAST_MANAGEMENT: ['PLAN_KEY_4'],
  ACCESS_FMI_NEWSLETTER_MANAGEMENT: ['PLAN_KEY_5'],
  ACCESS_COVERSHEET_MANAGEMENT: ['PLAN_KEY_7'],
  ACCESS_GROUP_TRANNING_MANAGEMENT: ['PLAN_KEY_6'],
  ACCESS_ENHANCED_COVERSHEET_PAKET_MANAGEMENT: ['PLAN_KEY_10'],
  ACCESS_ECONOMICS_TO_GROUPBY_101_MANAGEMENT: ['PLAN_KEY_12'],
  ACCESS_ALLOWED: ['PLAN_KEY_0'],
};
